import React from 'react';
import './styles.css';


const Third = () => {
    const imageUrl =  process.env.PUBLIC_URL + '/images/home_third_block.png'

    return (
        <div className="home-third-block">
            <div className="home-third-block__wrapper">
                <div className="home-third-block__text-wrapper">
                    <h1 className="home-third-block__title">Grow Your Business.<br />Worldwide.</h1>
                    <p className="home-third-block__text">Join the <span className="home-third-block__text_bold">DISTLL</span> Marketplace and move into the New Economy.</p>
                </div>
                <div className="home-third-block__image-wrapper">
                    <img className="home-third-block__image" src={imageUrl} alt="Home third block" />
                </div>
            </div>
        </div>
    )
}

export default Third
