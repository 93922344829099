import React from 'react';
import './styles.css';


const First = () => {
    const imageUrl =  process.env.PUBLIC_URL + '/images/bac_first_block.png'

    return (
        <div className="bac-first-block">
            <div className="bac-first-block__wrapper">
                <div className="bac-first-block__text-wrapper">
                    <h1 className="bac-first-block__title-bold">Grow</h1>
                    <h2 className="bac-first-block__title">New revenue opportunities at scale.</h2>
                    <div className="bac-first-block__text">
                        <p className="bac-first-block__text-paragraph"><span className="bac-first-block__text-bold">DISTLL</span> connects you to your target audiences via our omnichannel network of retailers.</p>
                        <p className="bac-first-block__text-paragraph"><span className="bac-first-block__text-bold">DISTLL</span> is a simple, turnkey integration with your existing eCommerce solution.</p>
                        <p className="bac-first-block__text-paragraph"><span className="bac-first-block__text-bold">DISTLL</span> syndicates your products and content directly to millions of new consumers who may not see it in the noisy environments of social media or the traditional web.</p>
                    </div>
                </div>
                <div className="bac-first-block__image-wrapper">
                    <img className="bac-first-block__image" src={imageUrl} alt="Barands and Creators first block" />
                </div>
            </div>
        </div>
    )
}

export default First
