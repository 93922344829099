import React from 'react';
import './styles.css';


const Fourth = ({color}) => {
    const imageUrl = process.env.PUBLIC_URL + '/images/retailers_fourth_block.png'
    const blockStyles = {
        backgroundColor: color
    }

    return (
        <div className="retailers-fourth-block" style={blockStyles}>
            <div className="retailers-fourth-block__wrapper">
                <div className="retailers-fourth-block__image-wrapper">
                    <img className="retailers-fourth-block__image" src={imageUrl} alt="Retailers fourth block" />
                </div>
                <div className="retailers-fourth-block__text-wrapper">
                    <h1 className="retailers-fourth-block__title">Monetize</h1>
                    <p className="retailers-fourth-block__text">
                        <span className="retailers-fourth-block__text-bold">DISTLL</span> offers instant commerce integration and a totally new, turnkey revenue stream for your product. Maximize conversions and engagement with best-in-class targeting capability and performance. Expand your commerce business with zero time, capital, or overhead.
                    </p>
                    <p className="retailers-fourth-block__text">
                        Revolutionizing retail, <span className="retailers-fourth-block__text-bold">DISTLL</span> is a commerce gamechanger.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Fourth
