import React, { Fragment } from 'react';

const AcceptableUsePolicy = () => (
    <Fragment>
        <p>This Acceptable Use Policy outlines examples of prohibited conduct in connection with our Services. Note that any capitalized terms not defined in this Acceptable Use Policy have the meanings set forth in our Terms Of Service. If you have any comments or questions about this Acceptable Use Policy, feel free to contact us. Our Services are used by people to express themselves, build their brands and grow their businesses. We’re proud of the creative and inspiring ways that people use our Services, but we expect our users to use our platform responsibly. As a DISTLL user you agree not to misuse the Services or help anyone else do so. Below are some guidelines for what you should not do with the Services.</p>
        <h3 className="terms-head">1. Abusing And Disrupting The Services</h3>
        <dl className="indent">
            <dd>1.1. Don’t probe, scan or test the vulnerability of any system or network.</dd>
            <dd>1.2. Don’t breach or otherwise bypass any security or authentication measures.</dd>
            <dd>1.3. Don’t access, tamper with or use non-public areas or parts of the Services, or shared areas of the Services you haven't been invited to access.</dd>
            <dd>1.4. Don’t interfere with or disrupt any user, host or network (whether it’s DISTLL’s or someone else’s), for example by distributing malware or overloading, flooding or mail-bombing any part of the Services.</dd>
            <dd>1.5. Don’t take apart, decompile or reverse engineer any part of the Services in an effort to access things such as source code or algorithms.</dd>
            <dd>1.6. Don’t access, search or create accounts for the Services by any means other than our publicly supported interfaces (for example, scraping, spidering or crawling).</dd>
            <dd>1.7. Don’t take action that imposes an unreasonable load on our infrastructure or our third party providers. We reserve the right to determine what’s reasonable.</dd>
            <dd>1.8. Don’t provide your password to any other person, use any other person’s username and password, or otherwise manage the Services through shared credentials.</dd>
        </dl>
        <h3 className="terms-head">2. Spamming And Phishing</h3>
        <dl className="indent">
            <dd>2.1. Don’t use our Services to send spam, emails that violate CAN-SPAM or other laws, emails to people who have not explicitly consented (where such consent is required) or other unsolicited communications (bulk or otherwise), promotions or advertisements. Note, we reserve the right to determine what constitutes spam.</dd>
            <dd>2.2. Don’t send altered, deceptive or false source-identifying information (including any misleading or incorrect names, email addresses, subject lines), including without limitation phishing or spoofing.</dd>
        </dl>
        <h3 className="terms-head">3. Deceiving And Impersonating Others</h3>
        <dl className="indent">
            <dd>3.1. Don’t do anything that's false, fraudulent, inaccurate or deceiving.</dd>
            <dd>3.2. Don’t impersonate another person, company or entity.</dd>
            <dd>3.3. Don’t engage in misleading or unethical marketing or advertising.</dd>
        </dl>
        <h3 className="terms-head">4. Stealing</h3>
        <dl className="indent">
            <dd>4.1. Don’t use unauthorized credit cards, debit cards or other unauthorized payment devices on the Services.</dd>
            <dd>4.2. Don’t promote or advertise products or services other than your own without appropriate authorization.</dd>
            <dd>4.3. Don’t sell the Services unless specifically authorized to do so, or publish or use the Services on any network or system other than those provided by DISTLL unless previously authorized by DISTLL.</dd>
        </dl>
        <h3 className="terms-head">5. Infringing, Misappropriating And Violating Rights</h3>
        <dl className="indent">
            <dd>5.1 Infringing, Misappropriating And Violating Rights</dd>
            <dd>5.2. Don’t violate anyone’s privacy or publicity rights.</dd>
        </dl>
        <h3 className="terms-head">6. Other Improper Or Illegal Conduct</h3>
        <dl className="indent">
            <dd>6.1. Don’t threaten, harass, or abuse any individuals.</dd>
            <dd>6.2. Don’t incite violence.</dd>
            <dd>6.3. Don’t publish sexually explicit or obscene material.</dd>
            <dd>6.4. Don’t condone or promote self-harm.</dd>
            <dd>6.5. Don’t condone or promote violence against any person or group based on race, ethnicity, nationality, religion, gender, gender identity, sexual preference, age or disability.</dd>
            <dd>6.6. Don’t violate any laws through the Services, including without limitation all local laws regarding online conduct and acceptable content.</dd>
        </dl>
        <p>If we conclude, in our discretion, that you have misused the Services, we may take action against your Account or Your Applications. We try to ensure fair outcomes, but in all cases we reserve the right to remove any content or suspend or terminate your Account or Your Applications, without any refund of any amounts paid for the Services, without liability or notice to you, at any time and for any reason (except where prohibited by applicable law). We reserve the right to enforce, or not enforce, this Acceptable Use Policy in our sole discretion.</p>
        <p>You can report violations of these guidelines to us directly by emailing <a href="mailto:contact@distll.com?subject=Website Inquiry">contact@distll.com</a>.</p>
        <p>We may modify this Acceptable Use Policy from time to time, and will post the most current version on our site. If a modification meaningfully reduces your rights, we'll notify you in accordance with the procedures set forth in our Terms Of Service.</p>
    </Fragment>
)

export default AcceptableUsePolicy
