import React from 'react';
import './styles.css';


const Fourth = ({color}) => {
    const blockStyles = {
        backgroundColor: color
    }

    return (
        <div className="bac-fourth-block" style={blockStyles}>
            <div className="bac-fourth-block__wrapper">
                <h1 className="bac-fourth-block__title">Premium</h1>
                <h2 className="bac-fourth-block__subtitle">Best in class</h2>
                <div className="bac-fourth-block__column bac-fourth-block__global-column">
                    <span className="bac-fourth-block__text-bold">GLOBAL</span><br />Engage with millions of new consumers at massive scale with the world’s next-generation commerce platform.
                </div>
                <div className="bac-fourth-block__column bac-fourth-block__fast-column">
                    <span className="bac-fourth-block__text-bold">FAST</span><br />Schedule your activations and drops in minutes.
                </div>
                <div className="bac-fourth-block__column bac-fourth-block__targeted-column">
                    <span className="bac-fourth-block__text-bold">TARGETED</span><br />Leverage a range of targeting tools to reach your true customer.
                </div>
                <div className="bac-fourth-block__column bac-fourth-block__optimize-column">
                    <span className="bac-fourth-block__text-bold">OPTIMIZE</span><br />Schedule, track, and optimize activations straight from your dashboard to maximize impact.
                </div>
                <div className="bac-fourth-block__column bac-fourth-block__high-yield-column">
                    <span className="bac-fourth-block__text-bold">HIGH YIELD</span><br />Optimize your top of funnel and remarketing efforts.
                </div>
                <div className="bac-fourth-block__column bac-fourth-block__best-in-class-column">
                    <span className="bac-fourth-block__text-bold">BEST IN CLASS</span><br />Syndicate your products and content into premium retail environments and user experiences.
                </div>
            </div>
        </div>
    )
}

export default Fourth
