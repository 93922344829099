import React from 'react';

const MenuOpen = () => {
    return (
        <svg width="30" height="21" viewBox="0 0 30 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="3" fill="#F7ECF2"/>
            <rect y="9" width="30" height="3" fill="#F7ECF2"/>
            <rect y="18" width="30" height="3" fill="#F7ECF2"/>
        </svg>
    )
}

export default MenuOpen
