import React, { Fragment } from 'react';

const LogoGuidelines = () => (
    <Fragment>
        <p>
            The DISTLL brand includes the words, phrases, symbols, designs and other
            distinctive brand features associated with DISTLL and our services (“Brand
            Assets”).
        </p>
        <h3 className="terms-head">Requirements</h3>
        <p>Before grabbing a DISTLL logo, please be sure to follow our basic rules:</p>
        <ol>
            <li>Comply with our Brand Guidelines.</li>
            <li>Don’t alter the shape, proportion, color or orientation of the logos. Keep them black and white, and only as they appear below. (We know you have some great design ideas — put them to work with a new DISTLL app!)</li>
            <li>Provide at least as much padding around the logo as what we’ve displayed below. This helps our logo appear clean and uncluttered.</li>
        </ol>
        <p>Finally, if you have any questions about logo usage, you can reach us via <a href="mailto:contact@distll.com?subject=Website Inquiry">contact@distll.com</a>.</p>
    </Fragment>
)

export default LogoGuidelines
