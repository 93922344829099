import { useEffect, useMemo } from 'react';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';

const useRouter = () => {
    const params = useParams()
    const location = useLocation()
    const history = useHistory()
    const match = useRouteMatch()

    return useMemo(() => {
        return {
            push: history.push,
            replace: history.replace,
            pathname: location.pathname,
            query: {
                ...queryString.parse(location.search),
                ...params
            },
            match,
            location,
            history
        };
    }, [params, match, location, history]);
}


const ScrollToTopController = () => {
    const { pathname, search } = useRouter()

    useEffect(() => {
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        } catch (error) {
            window.scrollTo(0, 0)
        }
    }, [pathname, search])

    return null
}

export default ScrollToTopController
