import React from 'react';
import './styles.css';


const Second = ({color}) => {
    const blockStyles = {
        backgroundColor: color
    }

    return (
        <div className="bac-second-block" style={blockStyles}>
            <div className="bac-second-block__wrapper">
                <div className="bac-second-block__column">
                    <p className="bac-second-block__text-bold">Target your stories</p> and product drops with granular precision.
                </div>
                <div className="bac-second-block__column">
                    <p className="bac-second-block__text-bold">Strategize your activations</p> by demographics, location, lookalikes, and interest groups.
                </div>
                <div className="bac-second-block__column">
                    <p className="bac-second-block__text-bold">Serve the right products</p> and content to  the right customer at the right time.
                </div>
            </div>
        </div>
    )
}

export default Second
