import React, { Fragment } from 'react';

const BrandGuidelines = () => (
    <Fragment>
        <p>
            The DISTLL brand includes the words, phrases, symbols, designs and other
            distinctive brand features associated with DISTLL and our services (“Brand
            Assets”). Examples of our Brand Assets include the word “DISTLL” and our
            logos. Our Brand Assets are trade names, trademarks, service marks and trade
            dress of DISTLL.
        </p>
        <p>
            DISTLL encourages and supports other products and services that use and
            supplement our services. At the same time, we must protect our reputation
            and brand identity. So we ask that you follow these Brand Guidelines, which
            are intended to promote consistent use of our Brand Assets. This makes it
            easier for people to instantly recognize references to DISTLL, and prevents
            consumer confusion. These guidelines also help protect DISTLL’s intellectual
            property.
        </p>
        <p>
            If you don’t agree to these Brand Guidelines, you don’t have a right to, and
            shouldn’t, use the Brand Assets.
        </p>
        <h3 className="terms-head">General Brand Guidelines</h3>
        <strong>DO</strong>
        <ul>
            <li>Use our Brand Assets to refer to DISTLL, our services or anything else we offer.</li>
            <li>Write “DISTLL” as a single word, with all letters capitalized.</li>
            <li>Comply with our Logo Guidelines, Terms Of Service and Acceptable Use Policy.</li>
        </ul>
        <strong>DON'T</strong>
        <ul>
            <li>Use the Brand Assets in a way that suggests or implies partnership, sponsorship or endorsement by DISTLL.</li>
            <li>Modify or alter the Brand Assets.</li>
            <li>Incorporate the Brand Assets, or anything confusingly similar, into your trademarks, domain names, logos or similar content.</li>
            <li>Present the Brand Assets in a way that makes them the most prominent or distinctive feature of what you’re creating.</li>
            <li>Use the Brand Assets in merchandise or other products such as clothing, hats or mugs.</li>
            <li>Assert rights over the DISTLL brand or Brand Assets, whether by domain name registration, trademark registration or anything else.</li>
            <li>Use trademarks, domain names, logos or other content that imitate or could be confused with DISTLL.</li>
            <li>Feature DISTLL on materials associated with sexually explicit content, unlawful activity or other materials that violate our Terms Of Service and Acceptable Use Policy.</li>
        </ul>
        <p>To help us address future branding issues, we may modify these Brand Guidelines, without notice to you. We’ll post the most recent version on our website. You’re responsible for following any modified terms, so be sure to review these Brand Guidelines regularly.</p>
    </Fragment>
)

export default BrandGuidelines
