import React, { Fragment } from 'react'
import './header.css'

const Header = ({ title }) => (
    <Fragment>
        <h1 className="terms-title">{title}</h1>
        <h4 className="terms-subtitle">Effective Date: February 15, 2020</h4>
    </Fragment>
)

export default Header
