import React, { Fragment } from 'react';

const CopyrightPolicy = () => (
    <Fragment>
        <p>DISTLL respects intellectual property rights and expects its users to do the same. DISTLL responds expeditiously to claims of copyright infringement committed using the Services. In accordance with applicable law (including the Digital Millennium Copyright Act of 1998), DISTLL will, under appropriate circumstances, terminate the accounts of repeat copyright infringers. We also reserve the right, in our sole discretion, to terminate any account for actual or apparent copyright infringement. Note that any capitalized terms not defined in this Copyright Policy have the meanings set forth in our Terms Of Service. <strong>Submitting A Notice Of Infringement.</strong>To submit a notice of claimed copyright infringement, you will need to provide us with the following information:</p>
        <ol>
            <li>Identification of the copyrighted work claimed to have been infringed (e.g., a link to your original work or clear description of the materials allegedly being infringed upon);</li>
            <li>Identification of the infringing material and information reasonably sufficient to permit DISTLL to locate the material on the Services;</li>
            <li>Your contact information, including name, address, telephone number and email address;</li>
            <li>The following statements:</li>
            <blockquote>“I hereby state that the information in this notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed.”; and</blockquote>
            <li>A physical or electronic signature (typing your full name will suffice) of the copyright owner or a person authorized to act on their behalf. </li>
        </ol>
        <p>Deliver this notice, with all items completed, to DISTLL’s Designated Copyright Agent:</p>
        <dl>
            <dd>DISTLL, Inc.</dd>
            <dd>Attention: Copyright Agent</dd>
            <dd>PO Box 5274</dd>
            <dd>Santa Monica, CA 90409</dd>
            <dd>United States</dd>
            <dd><a href="mailto:contact@distll.com?subject=Website Inquiry">contact@distll.com</a></dd>
        </dl>
        <p>DISTLL’s response to notices of alleged copyright infringement may include the removal or restriction of access to allegedly infringing material. Please note that information provided in a notice of copyright infringement may be forwarded to the user who posted the allegedly infringing content.</p>
        <p><span className="bold">Bad Faith Notices.</span> Please be aware that under applicable law (including 17 U.S.C. § 512(f)), you may be liable for any damages, including without limitation costs and attorneys’ fees incurred by us or our users, if you knowingly materially misrepresent that material or activity is infringing. If you’re unsure whether the material you are reporting is in fact infringing, you may wish to contact an attorney before filing a notification with us.</p>
        <p><span className="bold">Submitting A Counter-Notification.</span> If you believe in good faith that a notice of copyright infringement has been wrongly filed against you, you may send us a counter-notice. To submit a counter-notice, please respond to our original email notification of removal with the following information:</p>
        <ol>
            <li>Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled (the description from the copyright notice will suffice);</li>
            <li>Your name, address and telephone number;</li>
            <li>A statement that you consent to the jurisdiction of Federal District Court for the judicial district in which your address is located (or the federal district courts located in Los Angeles County, California if your address is outside of the United States), and that you will accept service of process from the person who provided the original notification or an agent of such person; </li>
            <li>The following statement: "I swear, under penalty of perjury, that I have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled."; and</li>
            <li>A physical or electronic signature (typing your full name will suffice).</li>
        </ol>
        <p>Upon receipt of a valid counter-notification, DISTLL will forward it to the notifying party who submitted the original notice of infringement. The notifying party will then have ten business days to notify us that they have filed legal action relating to the allegedly infringing material. If we don’t receive any such notification within ten business days, we may restore the material to the Services.</p>
        <p>We may modify this Copyright Policy from time to time, and will post the most current version on our site. If a modification meaningfully reduces your rights, we'll notify you in accordance with the procedures set forth in our Terms Of Service.</p>
    </Fragment>
)

export default CopyrightPolicy
