import React, { Fragment } from 'react';

const PrivacyPolicy = () => (
    <Fragment>
        <p>
            Thanks for visiting DISTLL. DISTLL (“DISTLL”, “we”, “us” or “our”) respects
            your privacy. When it comes to your personal information, we believe in
            transparency, not surprises. That’s why we’ve set out here what personal
            information we collect, what we do with it and your choices and rights. By
            using any of DISTLL’s Services, you confirm you have agreed to the Terms Of
            Service and read and understood this Privacy Policy and our Cookie Policy.
        </p>
        <h3 className="terms-head">1. Some key terms</h3>
        <p>In our Privacy Policy, when we refer to “Users”, we mean our customers who use our Services, including visitors to our sites. We explain who we are in the “Who is DISTLL?” section below. The users, visitors and customers of, our Users’ applications are “End Users”. Any other capitalized terms not defined in this Privacy Policy have the meanings in our Terms Of Service.</p>
        <h3 className="terms-head">2. How does this Privacy Policy apply?</h3>
        <p>This Privacy Policy describes what we do with personal information that we collect and use for our own purposes (i.e., where we are a controller), such as your account information and information about how you use and interact with our Services, including information you submit to our customer support as well as certain information relating to your End Users.</p>
        <p>We use cookies and similar technologies. Our Cookie Policy describes what we do in that regard. </p>
        <p>We also host and process User Content for our Users. Our Users tell us what to do with User Content, and we follow their instructions. This Privacy Policy does not describe what we do with User Content on our Users’ instructions (i.e., as their processor). If you are an End User of one of our User’s applications and want to know how a User handles your information, you should check its privacy policy. If you want to know about what we do for our own purposes, read on.</p>
        <p>If you are a User, see our Data Processing Addendum to learn more about how we process User Content on your instructions or with your permission.</p>
        <h3 className="terms-head">3. Personal information we collect</h3>
        <p>We collect various personal information regarding you or your device. This includes the following:</p>
        <ul>
            <li>Information you provide to create an Account, specifically email address, first name and last name. If you sign up for Paid Services, we receive a portion of your payment information from our payment processor (such as the last four digits, the country of issuance and the expiration date of the payment card) and we ask you to select your jurisdiction.</li>
            <li>Your marketing preferences.</li>
            <li>The emails and other communications that you send us or otherwise contribute, such as customer support inquiries or posts to our customer message boards or forums. Please be aware that information on public parts of our sites is available to others.</li>
            <li>Information you share with us in connection with surveys, contests or promotions.</li>
            <li>Information from your use of the Services or Users’ applications. This includes: IP addresses, preferences, web pages and applications you visited prior to coming to our or our Users’ sites or applications, information about your browser, network or device (such as browser type and version, operating system, internet service provider, preference settings, unique device IDs and language and other regional settings), information about how you interact with the Services and our Users’ applications (such as timestamps, clicks, scrolling, browsing times, searches, transactions, referral pages, load times, and problems you may encounter, such as loading errors).</li>
            <li>Information we get from our partners to support our marketing initiatives, improve our Services and better monitor, manage and measure our ad campaigns, such as details about when our partner shows you one of our ads on or via its advertising platform.</li>
            <li>Other information you submit to us directly or through Third Party Services if you use a Third Party Service to create an Account (based on your privacy settings with such Third Party Service).</li>
        </ul>
        <h3 className="terms-head">4. How we collect personal information</h3>
        <p>We obtain personal information from various sources. We do this in three main ways:</p>
        <ul>
            <li>You provide some of it directly (such as by registering for an Account).</li>
            <li>We record some of it automatically when you use our Services or Users’ applications (including with technologies like cookies).</li>
            <li>We receive some of it from third parties (like when you register for an account using a Third Party Service or when you make payments to us using our payment processor).</li>
        </ul>
        <p>We’ve described this in more detail below.</p>
        <dl className="indent">
            <dd><span className="bold">a. Personal information you provide</span></dd>
            <dd>When you use our Services, we collect information from you in a number of ways.  For instance, we ask you to provide your name and email address to register and manage your Account. We also maintain your marketing preferences and the emails and other communications that you send us or otherwise contribute, such as customer support inquiries or posts to our customer message boards or forums. You might also provide us with information in other ways, including by responding to surveys, submitting a form or participating in contests or similar promotions.</dd>
            <dd><span className="bold">b. Personal information obtained from your use of our Services</span></dd>
            <dd>When you use our Services, we collect information about your activity on and interaction with the Services, such as your IP address(es), your device and browser type, the web page or application you visited before coming to our sites and applications, what pages on our sites and applications you visit and for how long and identifiers associated with your devices. If you’ve given us permission through your device settings, we may collect your location information in our mobile apps.</dd>
            <dd>If you are an End User of our Users’ applications, we also get information about your interactions with their applications, though we use this in anonymous, aggregated or pseudonymized form which does not focus on you individually. This information includes data received during the registration process, application usage, data necessary for order fulfillment, and opt-in data related to third parties for marketing purposes. If an End User purchases products via our Services, we will collect information about such purchase, including name, email, and products purchased. We use this data to evaluate, provide, protect or improve our Services (including by developing new products and services).</dd>
            <dd>Some of this information is collected automatically using cookies and similar technologies when you use our Services and our Users’ applications. You can read more about our use of cookies in our Cookie Policy. Some of this information is similarly collected automatically through your browser or from your device.</dd>
            <dd><span className="bold">c. Personal information obtained from other sources</span></dd>
            <dd>If you use a Third Party Service (such as Google) to register for an Account, the Third Party Service may provide us with your Third Party Service account information on your behalf, such as your name and email address (we don’t store passwords you use to access Third Party Services). Your privacy settings on the Third Party Service normally control what they share with us. Make sure you are comfortable with what they share by reviewing their privacy policies and, if necessary, modifying your privacy settings directly on the Third Party Service.</dd>
            <dd>If you sign up for Paid Services, we obtain limited information about your payment card from our payment processor, such as the last four digits, the country of issuance and the expiration date. Currently, our payment processor is Stripe. Stripe uses and processes your complete payment information in accordance with Stripe’s privacy policy.</dd>
        </dl>
        <h3 className="terms-head">5. How we use your personal information</h3>
        <p>We use the personal information we obtain about you to:</p>
        <ul>
            <li><span className="bold">Provision of the Services.</span> Create and manage your Account, provide and personalize our Services, process payments and respond to your inquiries.</li>
            <li><span className="bold">Communicating with you.</span> Communicate with you, including by sending you emails about your transactions and Service-related announcements.</li>
            <li><span className="bold">Surveys and contests.</span> Administer surveys, contests and other promotions.</li>
            <li><span className="bold">Promotion.</span> Promote our Services and send you tailored marketing communications about products, services, offers, programs and promotions of DISTLL and our partners and measure the success of those campaigns. For example, we may send different marketing communications to you based on your subscription plan or what we think may interest you based on other information we hold about you.</li>
            <li><span className="bold">Advertising.</span> Analyze your interactions with our Services and third parties’ online services so we can tailor our advertising to what we think will interest you. For example, we may decide not to advertise our Services to you on a social media site if you already signed up for Paid Services or we may choose to serve you a particular advertisement based on your subscription plan or what we think may interest you based on other information we hold about you.</li>
            <li><span className="bold">Customizing the Services.</span> Provide you with customized services. For example, we use your location information to determine your language preferences or display accurate date and time information. We also use cookies and similar technologies for this purpose, such as remembering which of Your Applications you most recently edited.</li>
            <li><span className="bold">Improving our Services.</span> Analyze and learn about how the Services are accessed and used, evaluate and improve our Services (including by developing new products and services and managing our communications) and monitor and measure the effectiveness of our advertising. We usually do this based on anonymous, pseudonymized or aggregated information which does not focus on you individually. For example, if we learn that most Users of Paid Services use a particular integration or feature, we might wish to expand on that integration or feature.</li>
            <li><span className="bold">Security.</span> Ensure the security and integrity of our Services.</li>
            <li><span className="bold">Third party relationships.</span> Manage our vendor and partner relationships.</li>
            <li><span className="bold">Enforcement.</span> Enforce our Terms Of Service and other legal terms and policies.</li>
            <li><span className="bold">Protection.</span> Protect our and others’ interests, rights and property (e.g., to protect our Users from abuse).</li>
            <li><span className="bold">Complying with law.</span> Comply with applicable legal requirements, such as tax and other government regulations and industry standards, contracts and law enforcement requests.</li>
            <p>We process your personal information for the above purposes when:</p>
            <li><span className="bold">Consent.</span> You have consented to the use of your personal information in a particular way. When you consent, you can change your mind at any time.</li>
            <li><span className="bold">Performance of a contract.</span> We need your personal information to provide you with services and products requested by you, or to respond to your inquiries. In other words, so we can perform our contract with you or take steps at your request before entering into one. For example, we need your email address so you can sign in to your DISTLL account.</li>
            <li><span className="bold">Legal obligation.</span> We have a legal obligation to use your personal information, such as to comply with applicable tax and other government regulations or to comply with a court order or binding law enforcement request.</li>
            <li><span className="bold">Legitimate interests.</span> We have a legitimate interest in using your personal information. In particular, we have a legitimate interest in the following cases:</li>
            <ul>
                <li>To operate the DISTLL business and provide you with tailored advertising and communications to develop and promote our business.</li>
                <li>To analyze and improve the safety and security of our Services - we do this as it is necessary to pursue our legitimate interests in ensuring DISTLL is secure, such as by implementing and enhancing security measures and protections and protecting against fraud, spam and abuse.</li>
                <li>To provide and improve the Services, including any personalized services - we do this as it is necessary to pursue our legitimate interests of providing an innovative and tailored offering to our Users on a sustained basis.</li>
                <li>To share your personal information with other DISTLL group companies that help us provide and improve the Services.</li>
                <li>To anonymize and subsequently use anonymized information.</li>
            </ul>
            <li><span className="bold">Protecting you and others.</span> To protect your vital interests, or those of others.</li>
            <li><span className="bold">Others’ legitimate interests.</span> Where necessary for the purposes of a third party’s legitimate interests, such as our partners who have a legitimate interest in delivering tailored advertising to you and monitoring and measuring its effectiveness or our Users who have a legitimate interest in having their sites function properly and securely and analyzing the usage of their sites so they can understand trends and improve their services.</li>
        </ul>
        <h3 className="terms-head">6. How we share your personal information</h3>
        <p>We share personal information in the following ways:</p>
        <ul>
            <li><span className="bold">Affiliates.</span> We share personal information with our affiliates when it is reasonably necessary or desirable, such as to help provide services to you or analyze and improve the services we or they provide.</li>
            <li><span className="bold">Users.</span>  We share with our Users data regarding usage by End Users of their applications. For example, we provide a User with information about what web page or application the End User visited before coming to their application and how their End Users interacted with their application. This is so Users can analyze the usage of their application and improve their services.</li>
            <li><span className="bold">Business partners.</span>  We may share personal information with business partners. For example, we may share your personal information when our Services are integrated with their Third Party Services, but only when you have been informed or would otherwise expect such sharing.</li>
            <li><span className="bold">Service providers.</span> We share personal information with our service providers that perform services on our behalf. For example, we may use third parties to help us provide customer support, manage our advertisements on other sites, send marketing and other communications on our behalf or assist with data storage.</li>
            <li><span className="bold">Process payments.</span> We transmit your personal information via an encrypted connection to our payment processor.</li>
            <li><span className="bold">Following the law or protecting rights and interests.</span> We disclose your personal information if we determine that such disclosure is reasonably necessary to comply with the law, protect our or others’ rights, property or interests (such as enforcing our Terms Of Service) or prevent fraud or abuse of DISTLL or our Users or End Users. In particular, we may disclose your personal information in response to lawful requests by public authorities, such as to meet national security or law enforcement requirements.</li>
            <li><span className="bold">Advertising.</span> We share personal information with third parties so they and we can provide you with tailored advertising and measure and monitor its effectiveness. For example, we may share your pseudonymized email address with a third party social media platform on which we advertise to avoid serving DISTLL ads to people who already use DISTLL.</li>
            <li><span className="bold">Business transfers.</span> If we're involved in a reorganization, merger, acquisition or sale of some or all of our assets, your personal information may be transferred as part of that deal.</li>
        </ul>
        <h3 className="terms-head">7. Your rights and choices</h3>
        <p>Where applicable law requires (and subject to any relevant exceptions under law), you may have the right to access, update, change or delete personal information. You can access, update, change or delete personal information either directly in your Account or by contacting us at <a href="mailto:contact@distll.com?subject=Website Inquiry">contact@distll.com</a> to request the required changes. You can exercise your other rights (including deleting your Account) by contacting us at the same email address.</p>
        <p>You can also elect not to receive marketing communications by changing your preferences in your Account or by following the unsubscribe instruction in such communications.</p>
        <p>Please note that, for technical reasons, there is likely to be a delay in deleting your personal Information from our systems when you ask us to delete it. We also will retain personal Information in order to comply with the law, protect our and others’ rights, resolve disputes or enforce our legal terms or policies, to the extent permitted under applicable law.</p>
        <p>You may have the right to restrict or object to the processing of your personal information or to exercise a right to data portability under applicable law. You also may have the right to lodge a complaint with a competent supervisory authority, subject to applicable law. </p>
        <p>Additionally, if we rely on consent for the processing of your personal information, you have the right to withdraw it at any time and free of charge. When you do so, this will not affect the lawfulness of the processing before your consent withdrawal.</p>
        <p>Our Cookie Policy explains how you can manage cookies and similar technologies.</p>
        <p>If you are an End User of one of our User’s applications, you should contact them to exercise your rights with respect to any information they hold about you.</p>
        <h3 className="terms-head">8. How we protect your personal information</h3>
        <p>While no service is completely secure, we are dedicated to keeping personal information safe. We maintain administrative, technical and physical safeguards that are intended to appropriately protect against accidental or unlawful destruction, accidental loss, unauthorized alteration, unauthorized disclosure or access, misuse and any other unlawful form of processing of, the personal information in our possession. We employ security measures such as using firewalls to protect against intruders, building redundancies throughout our network (so that if one server goes down, another can cover for it) and testing for and protecting against network vulnerabilities.</p>
        <h3 className="terms-head">9. How we retain your personal information</h3>
        <p>We retain personal information regarding you or your use of the Services for as long as your Account is active or for as long as needed to provide you or our Users with the Services. We also retain personal information for as long as necessary to achieve the purposes described in this Privacy Policy, for example, to comply with our legal obligations, to protect us in the event of disputes and to enforce our agreements and to protect our and others’ interests.</p>
        <p>The precise periods for which we keep your personal information vary depending on the nature of the information and why we need it. Factors we consider in determining these periods include the minimum required retention period prescribed by law or recommended as best practice, the period during which a claim can be made with respect to an agreement or other matter, whether the personal information has been aggregated or pseudonymized, and other relevant criteria. For example, the period we keep your email address is connected to how long your Account is active, while the period for which we keep a support message is based on how long has passed since the last submission in the thread.</p>
        <p>As Users may have a seasonal application or come back to us after an Account becomes inactive, we don’t immediately delete your personal information when your trial expires or you cancel all Paid Services. Instead, we keep your personal information for a reasonable period of time, so it will be there for you if you come back.</p>
        <p>You may delete your Account by contacting us at <a href="mailto:contact@distll.com?subject=Website Inquiry">contact@distll.com</a> and DISTLL will delete the personal information it holds about you (unless we need to retain it for the purposes set out in this Privacy Policy).</p>
        <p>Please note that in the course of providing the Services, we collect and maintain aggregated, anonymized or de-personalized information which we may retain indefinitely.</p>
        <h3 className="terms-head">10. Data transfers</h3>
        <p>Personal information that you submit through the Services may be transferred to countries other than where you live, such as, for example, to servers not located in the U.S. We also store personal information locally on the devices you use to access the Services.</p>
        <p>Your personal information may be transferred to countries that do not have the same data protection laws as the country in which you initially provided the information.</p>
        <p>We rely upon a number of means to transfer personal information which is subject to the European General Data Protection Regulation (“GDPR”) in accordance with Chapter V of the GDPR.  These include:</p>
        <ul>
            <li><span className="bold">Privacy Shield.</span> We transfer, in accordance with Article 45 of the GDPR, personal information to companies that have certified their compliance with the EU-U.S. or Swiss-U.S. Privacy Shield Frameworks (each individually and jointly, the “Privacy Shield”), including DISTLL, Inc.</li>
            <li><span className="bold">Standard data protection clauses.</span> We may, in accordance with Article 46 of the GDPR, transfer personal information to recipients that have entered into the European Commission approved contract for the transfer of personal data outside the European Economic Area.</li>
            <li><span className="bold">Other means.</span> We may, in accordance with Articles 45 and 46 of the GDPR, transfer personal information to recipients that are in a country the European Commission or a European data protection supervisory authority has confirmed, by decision, offers an adequate level of data protection, pursuant to an approved certification mechanism or code of conduct, together with binding enforcement commitments from the recipient to apply the appropriate safeguards, including as regards data subjects’ rights, or to processors which have committed to comply with binding corporate rules.</li>
            <li><span className="bold"></span></li>
        </ul>
        <p>You can find out more information about these transfer mechanisms here.</p>
        <h3 className="terms-head">11. Privacy Shield</h3>
        <p>DISTLL, Inc has certified its compliance to the Privacy Shield.</p>
        <p>DISTLL is committed to treating personal information received from the European Economic Area and Switzerland pursuant to the Privacy Shield Frameworks in accordance with the applicable Principles. You can learn more about the Frameworks and Principles by visiting https://www.privacyshield.gov/.</p>
        <p>Our accountability for personal information we receive under the Privacy Shield and subsequently transfer to a third party is described in the Privacy Shield Principles. In particular, we may use third parties to process data on our behalf as described in this Privacy Policy, and we remain liable if they do so in a manner inconsistent with the Privacy Shield Principles, unless we prove that we are not responsible for the event giving rise to the damage.</p>
        <p>If you have a question or complaint you believe to be within the scope of Privacy Shield policies, please contact us first at <a href="mailto:contact@distll.com?subject=Website Inquiry">contact@distll.com</a>, or using the contact details in the “How to contact us” section below. We'll respond within 45 days.</p>
        <p>For any complaints that we can’t resolve directly, JAMS is the independent organization responsible for reviewing and resolving complaints about Privacy Shield compliance. You can contact JAMS free of charge at https://www.jamsadr.com/eu-us-privacy-shield. JAMS is an alternative dispute resolution provider based in the U.S.</p>
        <p>If your concern still isn't addressed by JAMS, you may be entitled to a binding arbitration under the Privacy Shield Principles. For purposes of enforcing compliance with the Privacy Shield, DISTLL, Inc. is subject to the investigatory and enforcement authority of the U.S. Federal Trade Commission.</p>
        <h3 className="terms-head">12. End Users’ personal information</h3>
        <p>Our customers who have created an application using DISTLL are responsible for what they do with the personal information they collect, directly or through DISTLL, about their End Users.  This section is directed to such customers.</p>
        <dl className="indent">
            <dd><span className="bold">a. Your relationship with End Users</span></dd>
            <dd>If you’re one of our Users, you will collect personal information about your End Users. For example, during checkout you may ask your End Users to provide their name, address, email address and payment information so that you can complete their orders. You may also use cookies and similar technologies to analyze usage and other trends.</dd>
            <dd>You're solely responsible for complying with any laws and regulations that apply to your collection and use of your End Users’ information, including personal information you collect about them from us or using DISTLL functionality or cookies or similar technologies.</dd>
            <dd>You must publish your own privacy and cookie policy and comply with them.</dd>
            <dd>We’re not liable for your relationship with your End Users or how you collect and use personal information about them (even if you collect it from us or using DISTLL functionality or cookies or similar technologies) and we won’t provide you with any legal advice regarding such matters.</dd>
            <dd><span className="bold">b. End User payment information</span></dd>
            <dd>Your End Users’ payment information may be processed via third party eCommerce Payment Processors with which you integrate your Account, in accordance with such eCommerce Payment Processors’ terms and policies. We transmit your End Users’ complete payment information when they initially provide or update it only so that we can pass it along to the eCommerce Payment Processors you agree to use. We don’t collect or store your End Users’ payment information.</dd>
        </dl>
        <h3 className="terms-head">13. Updates to this Privacy Policy</h3>
        <p>We’ll update this Privacy Policy from time to time to reflect changes in technology, law, our business operations or any other reason we determine is necessary or appropriate. When we make changes, we’ll update the “Effective Date” at the top of the Privacy Policy and post it on our sites. If we make material changes to it or the ways we process personal information, we’ll notify you (by, for example, prominently posting a notice of the changes on our sites before they take effect or directly sending you a notification). We encourage you to check back periodically to review this Privacy Policy for any changes since your last visit.  This will help ensure you better understand your relationship with us, including the ways we process your personal information.</p>
        <h3 className="terms-head">14. Who is DISTLL?</h3>
        <p>When we say “DISTLL” (or “we”, “us” or “our”), we mean: DISTLL, Inc. </p>
        <p>If your place of residence or principal place of business changes, the DISTLL entity that controls your personal information may change and will be determined by your new residence or principal place of business from the date it changes.</p>
        <h3 className="terms-head">15. How to contact us</h3>
        <p>If you have questions, comments or complaints about this Privacy Policy or our privacy practices or if you would like to exercise your rights and choices, please email us at <a href="mailto:contact@distll.com?subject=Website Inquiry">contact@distll.com</a>, or write to us at the addresses below:</p>
        <address>DISTLL, Inc. <br /> Attention: Legal - Privacy<br />PO Box 5274<br />Santa Monica, CA 90409<br />United States</address>
    </Fragment>
)

export default PrivacyPolicy
