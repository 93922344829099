import React from 'react';
import './styles.css';


const Second = () => {
    const windowWidth = document.body.clientWidth
    const imageUrl = windowWidth > 1199 ? process.env.PUBLIC_URL + '/images/home_second_block_desktop.png' : process.env.PUBLIC_URL + '/images/home_second_block_mobile.png'

    return (
        <div className="home-second-block">
            <div className="home-second-block__wrapper">
                <div className="home-second-block__text-wrapper">
                    <h1 className="home-second-block__title">New Revenue</h1>
                    <h2 className="home-second-block__title_bold">At Scale.</h2>
                    <p className="home-second-block__text"><span className="home-second-block__text_bold">DISTLL</span> connects brands to an omnichannel network of retailers, bringing premium products to millions of new consumers.</p>
                </div>
                <div className="home-second-block__image-wrapper">
                   <img className="home-second-block__image" src={imageUrl} alt="Home second block" />
                </div>
            </div>
        </div>
    )
}

export default Second
