import React from "react";
import { withRouter } from 'react-router';
import ScrollToTopController from "ScrollToTopController";
import Header from "components/molecules/header";
import Footer from "components/molecules/footer";
import {
  AppProvider,
  Page,
  Card,
  List
} from "@shopify/polaris";
import "./styles.scss";

const AppDocumentation = ({ history }) => {
  const color = "#B5264F";
  const backgroundColor = "#F7ECF2";

  const handleClickContactSupport = () => history.replace("/support")

  return (
    <div>
      <ScrollToTopController />
      <Header color={color} backgroundColor={backgroundColor} />
      <div className="app-documentation">
        <div className="app-documentation__wrapper">
          <div className="app-documentation__text-wrapper">
            <h1 className="app-documentation__title">
              Shopify App Documentation.
            </h1>
            <p className="app-documentation__text">
              The DISTLL Shopify Sales Channel App documentation for Brands.
            </p>
          </div>
        </div>
        <div className="app-documentation__polaris">
          <AppProvider>
            <Page>
              <Card title="What is DISTLL">
                <Card.Section>
                  <p>DISTLL is a Next Generation Commerce And Storytelling Solution.</p><br />
                  <p>DISTLL connects brands to an omnichannel network of retailers, bringing premium products to millions of new consumers.</p><br />
                  <p>Simply connect your Shopify store to send your products directly to a new network of retailers and  consumers.</p>
                </Card.Section>
                <Card.Section title="Getting started">
                  <p>Setting up an account with DISTLL via the sales channel app on Shopify.</p><br />
                  <List type="number">
                    <List.Item>Login to your Shopify account</List.Item>
                    <List.Item>Go to Apps in the left navigation of your Shopify</List.Item>
                    <List.Item>Search for DISTLL in the Shopify app marketplace</List.Item>
                    <List.Item>Install the DISTLL Sales Channel App</List.Item>
                    <List.Item>Click Connect on the DISTLL App home page</List.Item>
                    <List.Item>Complete the Sign Up form</List.Item>
                    <List.Item>Your account is now created</List.Item>
                    <List.Item>Personalize your account as you finish onboarding</List.Item>
                    <List.Item>Schedule activations that send your products to desired retailers.</List.Item>
                  </List>
                </Card.Section>
              </Card>
              
              <Card title="Sell your products to retailers">
                <Card.Section>
                  <p>Schedule product activations to sell to our network of retailers.</p><br />
                  <List type="number">
                    <List.Item>Choose and Add Retailers from the brand dashboard</List.Item>
                    <List.Item>Select the Retail channel you wish to syndicate products to</List.Item>
                    <List.Item>Select a retailer and schedule an activation</List.Item>
                    <List.Item>Select New Activation</List.Item>
                    <List.Item>Select Product Drop to send your products to the retailer</List.Item>
                    <List.Item>Add a title</List.Item>
                    <List.Item>Select Add Products to choose products for your activation</List.Item>
                    <List.Item>Set start and stop dates for your activation</List.Item>
                    <List.Item>Click Schedule and your activation will begin at the scheduled start date</List.Item>
                  </List>
                </Card.Section>
              </Card>
              
              <Card title="Ending and updating Product Activations">
                <Card.Section>
                  <p>Common questions on managing product activations</p>
                </Card.Section>
                <Card.Section title="Live Activations">
                  <p>Live activations can be stopped at any point, and the associated products will be removed from the retailer’s channel.</p><br />
                  <p>Simply mouse over the activation and click "Delete".</p><br />
                  <p>Confirm the deletion of the activation.</p><br />
                  <p>This will not delete or change any of your products in your Shopify store. It only removes that particular product activation from that retailer.</p><br />
                  <p>Live product activations cannot be edited.</p>
                </Card.Section>
                <Card.Section title="Draft Activations">
                  <p>Draft activations can be edited by mousing over the activation and selecting Edit.</p><br />
                  <p>Update the activation and re-schedule as before.</p><br />
                  <p>Live activations cannot be edited.</p>
                </Card.Section>
              </Card>
              
              <Card title="Support">
                <Card.Section>
                  <p>Please contact us with any questions or help using the app.</p>
                  <div className="app-documentation__contact-button" onClick={handleClickContactSupport}>Contact</div>
                </Card.Section>
              </Card>
            </Page>
          </AppProvider>
        </div>
      </div>
      <Footer color={color} />
    </div>
  );
};

export default withRouter(AppDocumentation);
