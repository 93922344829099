import React from 'react';
import ScrollToTopController from 'ScrollToTopController';
import Header from 'components/molecules/header';
import First from 'components/molecules/blocks/brands-and-creators/first';
import Second from 'components/molecules/blocks/brands-and-creators/second';
import Third from 'components/molecules/blocks/brands-and-creators/third';
import Fourth from 'components/molecules/blocks/brands-and-creators/fourth';
import Fifth from 'components/molecules/blocks/brands-and-creators/fifth';
import RequestADemo from 'components/molecules/request-a-demo';
import Footer from 'components/molecules/footer';

const BrandsAndCreators = () => {
    const color = "#521A33"
    const backgroundColor = "#F7ECF2"
    const pageStyles = {
        color: color
    }

    return (
        <div style={pageStyles}>
            <ScrollToTopController />
            <Header color={color} backgroundColor={backgroundColor} />
            <First />
            <Second color={color} />
            <Third />
            <Fourth color={color} />
            <Fifth color={color} />
            <RequestADemo color={color} />
            <Footer color={color} />
        </div>
    )
}

export default BrandsAndCreators
