import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from 'components/organisms/home';
import BrandsAndCreators from 'components/organisms/brands-and-creators';
import Retailers from 'components/organisms/retailers';
import About from 'components/organisms/about';
import Contact from 'components/organisms/contact';
import Support from 'components/organisms/support';
import AppDocumentation from 'components/organisms/app-documentation';
import Terms from 'components/organisms/terms';
import '@shopify/polaris/dist/styles.css';

const App = () => {
  return (
    <BrowserRouter>
      <Route
        path="/"
        component={Home}
        exact
      />
      <Route
        path="/brands-and-creators"
        component={BrandsAndCreators}
        exact
      />
      <Route
        path="/retailers"
        component={Retailers}
        exact
      />
      <Route
        path="/about"
        component={About}
        exact
      />
      <Route
        path="/contact"
        component={Contact}
        exact
      />
      <Route
        path="/support"
        component={Support}
        exact
      />
      <Route
        path="/app-documentation"
        component={AppDocumentation}
        exact
      />
      <Switch>
        <Route
          path="/terms/:title"
          component={Terms}
          exact
        />
      </Switch>
    </BrowserRouter>
  )
}

export default App
