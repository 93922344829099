import React from 'react';
import './styles.css';


const First = () => {
    return (
        <div className="about-first-block">
            <div className="about-first-block__wrapper">
                <h1 className="about-first-block__title">About</h1>
                <p className="about-first-block__text">DISTLL empowers brands and creators with next generation commerce and storytelling tools. Our solitary goal is to help you tell your story and amplify your revenue.</p>
                <p className="about-first-block__text">DISTLL. The commerce solution built by a team of world class brand builders, engineers, and technologists.</p>
            </div>
            <div className="about-first-block__background-image"></div>
        </div>
    )
}

export default First
