import React, { Fragment } from 'react';

const CookiePolicy = () => (
    <Fragment>
        <p>This Cookie Policy describes how DISTLL uses cookies and similar technologies to provide, customize, evaluate, improve, promote and protect our Services. Note that any capitalized terms not defined in this Cookie Policy have the meanings set forth in our Terms Of Service. If you have any comments or questions about this Cookie Policy, feel free to contact us.</p>
        <p><span className="bold">Cookies.</span> Cookies are small pieces of text sent to your browser when you visit a site. They serve a variety of functions, like enabling us to remember certain information you provide to us as you navigate between pages on the Services. We use cookies on the website and associated domains of www.distll.com and on DISTLL web and mobile applications for the following purposes:</p>
        <ul>
            <li><span className="bold">Authentication, Customization, Security and Other Functional Cookies.</span> Cookies help us verify your Account and device and determine when you’re logged in, so we can make it easier for you to access the Services and provide the appropriate experiences and features. We also use cookies to help prevent fraudulent use of login credentials and to remember choices you’ve made on the Services, such as your language preference.</li>
            <li><span className="bold">Performance And Analytics</span>. Cookies help us analyze how the Services are being accessed and used, and enable us to track performance of the Services. For example, we use cookies to determine if you viewed a page or opened an email. This helps us provide you with information that you find interesting.</li>
            <li><span className="bold">Third Parties.</span> Third Party Services may use cookies to help you sign into their services from our Services. Any such third party cookie usage is governed by the policy of the third party placing the cookie.</li>
            <li><span className="bold">DISTLL Ads</span>. We partner with third party publishers, advertising networks and service providers to manage our ads on other sites. Our third party partners may set cookies on your device or browser to gather information about your activities on the Services and other sites you visit, in order to provide you with DISTLL ads. For example, if you visit DISTLL and also use a social media platform, you may see a DISTLL ad in your social media newsfeed or timeline.</li>
            <li><span className="bold">Opting Out.</span> You can set your browser to not accept cookies, but this may limit your ability to use the Services. We currently don’t respond to DNT:1 signals from browsers visiting our Services. You can also opt out of receiving interest-based ads from certain ad networks here (or if located in the European Union, here).</li>
        </ul>
        <p><span className="bold">Device Identifiers.</span> We use device identifiers on DISTLL web and mobile applications to track, analyze and improve the performance of the Services and our ads.</p>
        <p><span className="bold">Third Party Tags.</span> We use and manage third party tags on the website and associated domains of www.distll.com and on DISTLL web and mobile applications. Third party tags may take the form of pixels or tracking snippets. We use pixels to learn how you interact with our site pages and emails, and this information helps us and our ad partners provide you with a more tailored experience. We use tracking snippets to capture data from your browser, make requests to a third party partner or set cookies on your device to store data. For example, if you see a DISTLL ad on a social media platform and choose to use DISTLL, we may use a tag to reduce the number of DISTLL ads you see on that platform. We use Google Tag Manager to manage our third party tag usage. This may cause other tags to be activated which may, for their part, collect data and set cookies under certain circumstances. Google Tag Manager does not store this data.</p>
        <p><span className="bold">Cookies on Your Sites.</span> We may update this Cookie Policy from time to time. When we make changes, we’ll update the “Effective Date” at the top of the Cookie Policy and post it on our sites. We encourage you to check back periodically to review this Cookie Policy for any changes since your last visit.</p>
    </Fragment>
)

export default CookiePolicy
