import React from 'react';
import { NavLink } from 'react-router-dom';
import FooterLogoBig from 'components/atoms/icons/FooterLogoBig';
import FooterLogoSmall from 'components/atoms/icons/FooterLogoSmall';
import './styles.css';

const Footer = ({color}) => {
    const footerStyles = {
        backgroundColor: color
    }

    return (
        <footer className="footer" style={footerStyles}>
            <div className="footer__wrapper">
                <div className="footer__logo-column">
                    <FooterLogoBig className={"footer__logo-big"} />
                    <FooterLogoSmall className={"footer__logo-small"} />
                </div>
                <div className="footer__services-column">
                    <p className="footer__column-title">SERVICES</p>
                    <NavLink className="footer__column-item" to="/brands-and-creators">DISTLL for Brands</NavLink>
                    <NavLink className="footer__column-item" to="/retailers">DISTLL for Retailers</NavLink>
                    <NavLink className="footer__column-item" to="/support">Support</NavLink>
                </div>
                <div className="footer__company-column">
                    <p className="footer__column-title">COMPANY</p>
                    <NavLink className="footer__column-item" to="/about">About</NavLink>
                    <NavLink className="footer__column-item" to="/contact">Contact</NavLink>
                    <NavLink className="footer__column-item" to="/app-documentation">App documentation</NavLink>
                </div>
                <div className="footer__legal-column">
                    <p className="footer__column-title">LEGAL</p>
                    <NavLink className="footer__column-item" to="/terms/terms-of-service">Terms of Service</NavLink>
                    <NavLink className="footer__column-item" to="/terms/privacy-policy">Privacy Policy</NavLink>
                </div>
                <div className="footer__copyright-column">
                    © {new Date().getFullYear()} DISTLL. All Rights Reserved.
                </div>
            </div>
        </footer>
    )
}

export default Footer
