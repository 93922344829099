import React from 'react';
import {
    First,
    Second,
    Third,
    Fourth,
    Fifth,
    Sixth,
    Seventh,
    Eighth,
    Ninth,
    Tenth,
    Eleventh,
    Twelfth
} from 'components/atoms/icons/Brands';
import './styles.css';


const FifthBlock = ({color}) => {
    const imageUrl =  process.env.PUBLIC_URL + '/images/retailers_fifth_block.png'

    return (
        <div className="retailers-fifth-block">
            <div className="retailers-fifth-block__wrapper">
                <h1 className="retailers-fifth-block__title">MRKTNG</h1>
                <h2 className="retailers-fifth-block__subtitle">Crafting bespoke marketing activations.</h2>
                <div className="retailers-fifth-block__text-wrapper">
                    <p className="retailers-fifth-block__text">
                        Drive more sales, more engagement, and more discovery with <span className="retailers-fifth-block__text-bold">DISTLL</span>.<br /><br /><span className="retailers-fifth-block__text-bold">DISTLL</span> acts like an agency, crafting custom marketing activations to boost customer engagement and increase revenue. We develop targeted promotions, strategic partnerships, and collaborations with <span className="retailers-fifth-block__text-bold">DISTLL</span> brand partners to grow your business.
                    </p>
                    <p className="retailers-fifth-block__text">
                        Our leadership team has a 40+ year track record in this space producing experiences, collaborations, limited editions, and content with a wide range of influential brands and creators including KAWS, Adidas, Nike, Supreme, Levis, OBEY, Wu Tang Clan, Warner Brothers, Universal, T-Mobile, Urban Outfitters, The Standard hotels, and hundreds of others.
                    </p>
                </div>
                <div className="retailers-fifth-block__brands-wrapper">
                    <div className="retailers-fifth-block__brands-column">
                        <First color={color} />
                        <Second color={color} />
                        <Fifth color={color} />
                        <Sixth color={color} />
                        <Ninth color={color} />
                        <Tenth color={color} />
                    </div>
                    <div className="retailers-fifth-block__brands-column">
                        <Third color={color} />
                        <Fourth color={color} />
                        <Seventh color={color} />
                        <Eighth color={color} />
                        <Eleventh color={color} />
                        <Twelfth color={color} />
                    </div>
                </div>
            </div>
            <div className="retailers-fifth-block__image-wrapper">
                <img className="retailers-fifth-block__image" src={imageUrl} alt="Retailers fifth block" />
            </div>
        </div>
    )
}

export default FifthBlock
