import React from 'react';
import ScrollToTopController from 'ScrollToTopController';
import Header from 'components/molecules/header';
import ContactUs from 'components/molecules/contact-us';
import Footer from 'components/molecules/footer';

const Contact = () => {
    const color = "#B5264F"
    const backgroundColor = "#F7ECF2"
    const pageStyles = {
        color: color
    }

    return (
        <div style={pageStyles}>
            <ScrollToTopController />
            <Header color={color} backgroundColor={backgroundColor} />
            <ContactUs color={color} />
            <Footer color={color} />
        </div>
    )
}

export default Contact
