import React from 'react';
import './styles.css';


const Third = () => {
    return (
        <div className="bac-third-block">
            <div className="bac-third-block__wrapper">
                <h1 className="bac-third-block__title">Monetize</h1>
                <p className="bac-third-block__text">
                    <span className="bac-third-block__text_bold">DISTLL</span> offers instant commerce integration and a totally new, turnkey revenue stream for your product. Maximize conversions and engagement with best-in-class targeting capability and performance. Expand your commerce business with zero time, capital, or overhead.
                    <br /><br />Revolutionizing retail, <span className="bac-third-block__text_bold">DISTLL</span> is a commerce gamechanger.
                </p>
            </div>
        </div>
    )
}

export default Third
