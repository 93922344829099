import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import styled from 'styled-components';
import HeaderLogo from 'components/atoms/icons/HeaderLogo';
import MenuOpen from 'components/atoms/icons/MenuOpen';
import helperWindow from "helpers/window";
import './styles.css';

class Header extends Component {
    state = {
        isVisibleMenu: false
    }

    toggleMenu = () => {
        return e => {
            e.preventDefault()
            this.setState({
                isVisibleMenu: !this.state.isVisibleMenu
            })
        }
    }

    render() {
        const { color, backgroundColor } = this.props
        const { isVisibleMenu } = this.state

        const headerStyles = {
            backgroundColor: color
        }
        const AnchorButton = styled(AnchorLink)`
            color: ${color};
            background-color: ${backgroundColor};

            &:hover {
                background-color: #fff;
            }
        `
        const NavLinkButton = styled(NavLink)`
            color: ${color};
            background-color: ${backgroundColor};

            &:hover {
                background-color: #fff;
            }
        `
        const navigationStyles = {
            backgroundColor: color,
        }
        let showMenuClass = ""
        if (isVisibleMenu) {
            showMenuClass = " active"
        }

        const { pathname } = this.props.location
        const requestADemoLinks = ['/', '/about', '/brands-and-creators', '/retailers']
        const isAnchorLink = requestADemoLinks.includes(pathname)
    
        return (
            <header className="header" style={headerStyles}>
                <div className="header__wrapper">
                    { helperWindow.isInIframe()
                        ?  (
                            <>
                                <div className="header__logo-column">
                                    <HeaderLogo />
                                </div>
                                <div className="header__navigation-lite" style={navigationStyles}>
                                    <a className="header__navigation-lite-item" href="https://api-marketplace.distll.com/">Home</a>
                                </div>
                            </>
                        )
                        :  (
                            <>
                                <div className="header__logo-column">
                                    <NavLink to="/"><HeaderLogo /></NavLink>
                                </div>
                                <div className={"navigation" + showMenuClass} style={navigationStyles}>
                                    <NavLink className="navigation__item" to="/brands-and-creators">Brands {'&'} Creators</NavLink>
                                    <NavLink className="navigation__item" to="/retailers">Retailers</NavLink>
                                    { isAnchorLink ?
                                        <AnchorButton className="navigation__item" href="#request-a-demo">Request a Demo</AnchorButton> :
                                        <NavLinkButton className="navigation__item" to="/">Request a Demo</NavLinkButton>
                                    }
                                    <a className="navigation__item" href="https://api-marketplace.distll.com/">Login</a>
                                </div>
                                <div className="header__icon-column" onClick={this.toggleMenu()}>
                                    <MenuOpen />
                                </div>
                            </>
                        )
                    }
                    
                </div>
            </header>
        )
    }
}

export default withRouter(Header)
