import React, { Component } from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import './styles.css';

const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class ContactUs extends Component {
    state = {
        isSubmited: false,
        data: {
            first_name: {
                value: '',
                errorDisplay: 'none',
                placeholder: 'First Name'
            },
            last_name: {
                value: '',
                errorDisplay: 'none',
                placeholder: 'Last Name'
            },
            email: {
                value: '',
                errorDisplay: 'none',
                placeholder: 'Email'
            },
            phone: {
                value: '',
                errorDisplay: 'none',
                placeholder: 'Phone'
            },
            message: {
                value: '',
                errorDisplay: 'none',
                placeholder: 'Message'
            }
        },
        isLoading: false
    }

    handleInputChange = (inputName, value) => {
        const { data } = this.state
        data[inputName].value = value
        this.setState({
            data: data
        })
    }

    validateData = () => {
        const { data } = this.state
        Object.keys(data).forEach(inputName => {
            data[inputName].errorDisplay = 'none'
        })
        let isValid = true
        for (const key in data) {
            if (data[key].value === '') {
                isValid = false
                data[key].errorDisplay = 'block'
            }
            if (key === 'email' && !emailRegEx.test(data[key].value)) {
                isValid = false
                data[key].errorDisplay = 'block'
            }
        }
        this.setState({
            data: data
        })
        return isValid
    }

    async sendData() {
        if(!this.validateData()) {
            return
        }
    
        this.setState({
            isLoading: true
        })

        let formatedData = {
            _subject: 'Distll Demo Inquiry - Test'
        }
        const { data } = this.state
        Object.keys(data).forEach(inputName => {
            formatedData[inputName] = data[inputName].value
        })
        formatedData._subject = 'Distll Website Inquiry'

        await axios({
            method: 'post',
            url: process.env.REACT_APP_FORM_URL,
            data: formatedData,
            headers: {
                accept: 'application/json'
            }
        }).then(() => {
            this.setState({
                isSubmited: true,
                isLoading: false
            })
        })
    }

    render() {
        const { color } = this.props
        if (this.state.isLoading) {
            return (
                <div className="contact-block">
                    <div className="contact-block__sending-wrapper">
                        <Loader
                            type="TailSpin"
                            color={color}
                            height={100}
                            width={100}
                            className="contact-block__loader"
                        />
                        <p className="contact-block__sending-text">Data is being processed ...</p>
                    </div>
                </div>
            )
        }
        if (this.state.isSubmited) {
            return (
                <div className="contact-block">
                    <div className="contact-block__success-wrapper">
                        <h1 className="contact-block__success-title">Thank you.</h1>
                        <p className="contact-block__success-text">Your message was sent and we will get back to you as soon as possible.</p>
                    </div>
                </div>
            )
        }
        const Button = styled.div`
            border: 2px solid ${color};
            &:hover {
                background-color: ${color};
            }
        `
        const { data } = this.state
        const inputs = Object.keys(data).map((inputName, i) => {
            const placeholder = data[inputName].placeholder
            const value = data[inputName].value
            const errorStyles = {
                display: data[inputName].errorDisplay
            }
            return (
                <div className="contact-form__input-wrapper" key={i}>
                    { inputName === 'message' ?
                        <textarea className="contact-form__input" placeholder={placeholder} value={value} onChange={e => this.handleInputChange(inputName, e.target.value)} /> :
                        <input className="contact-form__input" placeholder={placeholder} value={value} onChange={e => this.handleInputChange(inputName, e.target.value)} />
                    }
                    <p className="contact-form__input-error" style={errorStyles}>
                        { placeholder } { inputName === 'email' ? 'is not valid' : 'is required'}
                    </p>
                </div>
            )
        })

        return (
            <div className="contact-block">
                <div className="contact-block__wrapper">
                    <h1 className="contact-block__title">Contact Us</h1>
                    <form className="contact-form">
                        { inputs }
                        <Button className="contact-form__button" onClick={e => this.sendData()}>submit</Button>
                    </form>
                </div>
            </div>
        )
    }
}

export default ContactUs
