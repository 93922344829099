import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import Select from 'react-select';
import ScrollToTopController from 'ScrollToTopController';
import Header from 'components/molecules/header';
import TermsHeader from 'components/molecules/blocks/terms/Header';
import AcceptableUsePolicy from 'components/molecules/blocks/terms/AcceptableUsePolicy';
import BrandGuidelines from 'components/molecules/blocks/terms/BrandGuidelines';
import CookiePolicy from 'components/molecules/blocks/terms/CookiePolicy';
import CopyrightPolicy from 'components/molecules/blocks/terms/CopyrightPolicy';
import DataProcessingAddendum from 'components/molecules/blocks/terms/DataProcessingAddendum';
import LogoGuidelines from 'components/molecules/blocks/terms/LogoGuidelines';
import PrivacyPolicy from 'components/molecules/blocks/terms/PrivacyPolicy';
import TermsOfService from 'components/molecules/blocks/terms/TermsOfService';
import Footer from 'components/molecules/footer';
import './styles.css'

const termsComponents = {
    'acceptable-use-policy': {
        component: <AcceptableUsePolicy />,
        title: 'Acceptable Use Policy'
    },
    'brand-guidelines': {
        component: <BrandGuidelines />,
        title: 'Brand Guidelines'
    },
    'cookie-policy': {
        component: <CookiePolicy />,
        title: 'Cookie Policy'
    },
    'copyright-policy': {
        component: <CopyrightPolicy />,
        title: 'Copyright Policy'
    },
    'data-processing-addendum': {
        component: <DataProcessingAddendum />,
        title: 'Data Processing Addendum'
    },
    'logo-guidelines': {
        component: <LogoGuidelines />,
        title: 'Logo Guidelines'
    },
    'privacy-policy': {
        component: <PrivacyPolicy />,
        title: 'Privacy Policy'
    },
    'terms-of-service': {
        component: <TermsOfService />,
        title: 'Terms Of Service'
    }
}

class Terms extends Component {
    changeBehavior = selected => {
        this.props.history.push(selected.value)
    }

    render() {
        const color = "#B5264F"
        const backgroundColor = "#F7ECF2"
        const pageStyles = {
            color: color
        }

        const { title } = this.props.match.params
        const selectOptions = Object.keys(termsComponents).map(key => {
            return {
                value: '/terms/' + key,
                label: termsComponents[key].title
            }
        })
        const selectedOption = selectOptions.filter(item => item.value === '/terms/' + title ? true : false)[0]
        const selectStyles = {
            control: (styles, state) => ({
                ...styles,
                width: '100%',
                height: 35,
                maxWidth: 300,
                margin: 'auto',
                marginTop: 40,
                border: 'none',
                borderRadius: 0,
                borderBottom: '1px solid #DDD !important',
                cursor: 'pointer'
            }),
            indicatorSeparator: styles => ({
                display: 'none'
            }),
            menu: styles => ({
                width: 300,
                margin: 'auto',
                position: 'absolute',
                left: document.body.clientWidth / 2 - 150,
                top: 40,
                backgroundColor: '#FFF',
                boxShadow: '0 0 6px rgba(0, 0, 0, .1)'
            }),
            option: (styles, state) => ({
                color: state.isFocused ? color : '#333',
                padding: '4px 8px 4px 22px',
                cursor: 'pointer',
                position: 'relative'
            }),
            singleValue: styles => ({
                padding: 0,
                fontSize: 16,
                color: '#333',
                margin: 'auto 0px',
                marginLeft: -5
            })
        }

        if (!Object.keys(termsComponents).includes(title)) {
            return <Redirect to="/" />
        }

        return (
            <div style={pageStyles}>
                <ScrollToTopController />
                <Header color={color} backgroundColor={backgroundColor} />
                <div className="terms">
                    <Select
                        classNamePrefix="terms-select"
                        options={selectOptions}
                        value={selectedOption}
                        styles={selectStyles}
                        isSearchable={false}
                        onChange={selected => this.changeBehavior(selected)}
                    />
                    <div className="terms-content">
                        <TermsHeader title={termsComponents[title].title} />
                        {termsComponents[title].component}
                    </div>
                </div>
                <Footer color={color} />
            </div>
        )
    }
}

export default withRouter(Terms)

