import React, { Component } from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import './styles.css';

const Checked = ({color}) => {
    return (
        <svg className="request-form__custom-checkbox-marker" width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5 27C20.9558 27 27 20.9558 27 13.5C27 6.04416 20.9558 0 13.5 0C6.04416 0 0 6.04416 0 13.5C0 20.9558 6.04416 27 13.5 27Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M24.489 2L11.984 16.855L6.027 11.391L4 13.602L12.263 21.183L26.784 3.931L24.489 2Z" fill="white" />
        </svg>
    )
}

const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class RequestADemo extends Component {
    state = {
        isChecked: false,
        isSubmited: false,
        subscribeData: {
            first_name: {
                value: '',
                errorDisplay: 'none',
                placeholder: 'First Name'
            },
            last_name: {
                value: '',
                errorDisplay: 'none',
                placeholder: 'Last Name'
            },
            company: {
                value: '',
                errorDisplay: 'none',
                placeholder: 'Company'
            },
            email: {
                value: '',
                errorDisplay: 'none',
                placeholder: 'Email'
            },
            phone: {
                value: '',
                errorDisplay: 'none',
                placeholder: 'Phone'
            }
        },
        isLoading: false
    }

    toggleCheckbox = () => {
        this.setState({
            isChecked: !this.state.isChecked
        })
    }

    handleInputChange = (inputName, value) => {
        const { subscribeData } = this.state
        subscribeData[inputName].value = value
        this.setState({
            subscribeData: subscribeData
        })
    }

    validateSubscribeData = () => {
        const { subscribeData } = this.state
        Object.keys(subscribeData).forEach(inputName => {
            subscribeData[inputName].errorDisplay = 'none'
        })
        let isValid = true
        for (const key in subscribeData) {
            if (subscribeData[key].value === '') {
                isValid = false
                subscribeData[key].errorDisplay = 'block'
            }
            if (key === 'email' && !emailRegEx.test(subscribeData[key].value)) {
                isValid = false
                subscribeData[key].errorDisplay = 'block'
            }
        }
        this.setState({
            subscribeData: subscribeData
        })
        return isValid
    }

    async sendSubscribeData() {
        if(!this.validateSubscribeData()) {
            return
        }
    
        this.setState({
            isLoading: true
        })

        let formatedSubscribeData = {
            _subject: 'Distll Demo Inquiry - Test'
        }
        const { subscribeData } = this.state
        Object.keys(subscribeData).forEach(inputName => {
            formatedSubscribeData[inputName] = subscribeData[inputName].value
        })
        formatedSubscribeData._subject = 'Distll Demo Inquiry - ' + formatedSubscribeData.company

        await axios({
            method: 'post',
            url: process.env.REACT_APP_FORM_URL,
            data: formatedSubscribeData,
            headers: {
                accept: 'application/json'
            }
        }).then(() => {
            this.setState({
                isSubmited: true,
                isLoading: false
            })
        })
    }

    render() {
        const { color } = this.props
        if (this.state.isLoading) {
            return (
                <div className="request-block" id="request-a-demo">
                    <div className="request-block__sending-wrapper">
                        <div>
                        <Loader
	     type="TailSpin"
	     color={color}
	     height={100}
         width={100}
         className="request-block__loader"
	  />
                            <p className="request-block__sending-text">Data is being processed ...</p>
                        </div>
                    </div>
                </div>
            )
        }
        if (this.state.isSubmited) {
            return (
                <div className="request-block" id="request-a-demo">
                    <div className="request-block__success-wrapper">
                        <h1 className="request-block__success-title">Thank you.</h1>
                        <p className="request-block__success-text">Your message was sent and we will get back to you as soon as possible.</p>
                    </div>
                </div>
            )
        }
        const Button = styled.div`
            border: 2px solid ${color};
            &:hover {
                background-color: ${color};
            }
        `
        const { subscribeData } = this.state
        const inputs = Object.keys(subscribeData).map((inputName, i) => {
            const placeholder = subscribeData[inputName].placeholder
            const value = subscribeData[inputName].value
            const errorStyles = {
                display: subscribeData[inputName].errorDisplay
            }
            return (
                <div className="request-form__input-wrapper" key={i}>
                    <input className="request-form__input" placeholder={placeholder} value={value} onChange={e => this.handleInputChange(inputName, e.target.value)} />
                    <p className="request-form__input-error" style={errorStyles}>
                        { placeholder } { inputName === 'email' ? 'is not valid' : 'is required'}
                    </p>
                </div>
            )
        })

        return (
            <div className="request-block" id="request-a-demo">
                <div className="request-block__wrapper">
                    <h1 className="request-block__title">Request A Demo</h1>
                    <form className="request-form">
                        { inputs }
                        <label className="request-form__label" onClick={e => this.toggleCheckbox()}>
                            I want to receive DISTLL email news + updates (unsubscribe at any time).
                            <span className="request-form__custom-checkbox"></span>
                            {this.state.isChecked ? <Checked color={color} /> : ''}
                        </label>
                        <Button className="request-form__button" onClick={e => this.sendSubscribeData()}>submit</Button>
                    </form>
                </div>
            </div>
        )
    }
}

export default RequestADemo
