import React from 'react';
import './styles.css';


const Second = () => {
    const windowWidth = document.body.clientWidth
    const imageUrl = windowWidth > 1199 ? process.env.PUBLIC_URL + '/images/retailers_second_block_desktop.png' : process.env.PUBLIC_URL + '/images/retailers_second_block_desktop.png'

    return (
        <div className="retailers-second-block">
            <div className="retailers-second-block__wrapper">
                <div className="retailers-second-block__text-wrapper">
                    <h1 className="retailers-second-block__title">Grow</h1>
                    <h2 className="retailers-second-block__subtitle">New revenue opportunities at scale.</h2>
                    <p className="retailers-second-block__text">
                        <span className="retailers-second-block__text-bold">DISTLL</span> is a revolutionary commerce-content integration for every connected retail environment. Plug our network of the world’s best brands and creators directly into your product, adding an entirely new dimension to your user experience with exclusive content and merchandise.
                    </p>
                </div>
                <div className="retailers-second-block__image-wrapper">
                    <img className="retailers-second-block__image" src={imageUrl} alt="Retailers second block" />
                </div>
            </div>
        </div>
    )
}

export default Second
